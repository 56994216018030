@use '../variables' as *;

.services {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 4rem;
    width: 100%;

	&__link {
		display: block;
		width: 100%;
		font-size: 1.5rem;
		font-weight: 700;
		color: var(--green);
		padding-top: 2rem;
		text-align: right;
	}
}


@media (max-width: map-get($breakpoints, 'wide')){
	.services {
		gap: 3rem;
	}
}

@media (max-width: map-get($breakpoints, 'medium')){
	.services {
		gap: 4rem 3rem;
	}
}