@use '../variables' as *;

.service-card {
  width: 20rem;
  flex: 1 1 20rem;

  &__img {
    display: block;
    width: 100%;
    max-width: 100%;
    border-radius: var(--border-radius);
    margin-bottom: 1rem;
	overflow: hidden;
	box-shadow: 0px 2px 10px 0px rgba(#222, 0.15);

	img {
		display: block;
		width: 100%;
		transform: scale(1.05);
	}
  }

  &__name {
    font-weight: 700;
    font-size: 1.5rem;
    color: var(--purple);
    margin-bottom: 0.75rem;
  }

  &__note {
	display: block;
	margin-top: 1rem;
	font-weight: 500;
	color: var(--purple);
  }
  
  &__rates {
	display: flex;
	justify-content: flex-start;
	gap: 1rem;
	margin-bottom: 1.25rem;

	li {
		display: flex;
		justify-content: flex-start;
		gap: 0.25rem;
		color: var(--purple);
	}

	strong {
		font-weight: 500;
	}

	span {
		color: var(--black);
	}
  }

  &__description{
    color: var(--black);
  }
}

// Special breakpoint for the service cards. May implement element queries later
@media (max-width: 736px) and (min-width: map-get($breakpoints, 'tiny')){
	.service-card {
		&__name {
			font-size: 2rem;
		}

		&__rates li,
		&__description,
		&__note {
			font-size: 1.25rem;
		}
	}
}


@media (max-width: map-get($breakpoints, 'micro')){
	.service-card {
		&__rates {
			flex-direction: column;
			gap: 0.25rem;
		}
	}
}