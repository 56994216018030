@use '../variables' as *;

.motto {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  max-width: 960px;
  margin: 0 auto;

  &__img {
    width: 4rem;
  }

  &__text {
    text-align: center;
    font-size: 2.5rem;
    color: var(--purple);
    line-height: 1.5;
  }
}


@media (max-width: map-get($breakpoints, 'medium')){
	.motto {
		gap: 1.5rem;

		&__text {
			font-size: 2rem;
		}
		&__img {
			width: 3.5rem;
		}
	}
}