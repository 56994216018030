@use '../variables' as *;

.footer {
  width: 100%;
  background-color: var(--green);
  margin-top: auto;

	&__inner {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		padding: 3rem 1rem 5rem;
		max-width: var(--container);
		color: var(--white);
		margin: 0 auto;
 	 }

  &__top {
	width: 100%;
	border-bottom: 1px solid var(--white);
	padding-bottom: 3rem;
	margin-bottom: 3rem;
  }

  &__info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 3rem;
  }

  &__hr {
    width: 100%;
    height: 1px;
    background-color: var(--white);
    margin: 3rem 0;
  }

  &__info-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    font-size: 1.5rem;

    strong {
      font-size: 1rem;
    }
  }

  &__text {
    width: 100%;
    color: var(--white);
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  &__socials {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.75rem;
  }

  &__social-icon {
	a {
		display: inline-block;
	}

	svg {
		display: block;
		height: 2.25rem;

		path {
			fill: var(--white);
		}
	}
  }
}


@media (max-width: map-get($breakpoints, 'wide')) and (min-width: map-get($breakpoints, 'small')){
	.footer {
		&__inner {
			padding-bottom: 3rem;
			gap: 1rem;
			flex-wrap: nowrap;
		}

		&__top {
			flex: 1 1 50%;
			border-bottom: none;
			margin-bottom: 0;
			padding-bottom: 0;
		}

		&__info {
			flex-direction: column;
			flex: 1 1 50%;
		}
	}
}

@media (max-width: map-get($breakpoints, 'small')) {
	.footer {
		&__inner {
			padding-bottom: 3rem;
		}

		&__info {
			flex-direction: column;
			gap: 2rem;
			align-items: center;
			text-align: center;
		}
	}
}


@media (max-width: map-get($breakpoints, 'tiny')) {
	.footer {
		&__info {
			gap: 1.5rem;
		}
		&__info-item {
			font-size: 1.25rem;
		}
	}
}