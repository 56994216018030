@use 'variables' as *;

* {
	font-family: 'Montserrat', Helvetica, Arial, sans-serif;
}

html {
	scroll-behavior: smooth;
}

body {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

strong {
	font-weight: 700;
}

p {
	line-height: 1.5;
}

@media (hover: hover) {
	a {
		cursor: pointer;
		transition: all 400ms ease;
	
		&:hover {
			opacity: 0.8;
			transform: translateY(-4px);
		}
	}
	
}
  